export class UtilsHelper {
  public static IsJsonString(value: string) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  public static openModal(id: string) {
    const popup = document.getElementById(id);
    if (popup) {
      popup.classList.add('open');
    }
  }

  public static closeModal(id: string) {
    const popup = document.getElementById(id);
    if (popup) {
      popup.classList.remove('open');
    }
  }
}
